<template>
    <div>
        <date-range-picker
                ref="picker"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                :autoApply="true"
                :date-range='value'
                @update="updateValue"
        >
            <template v-slot:input="picker" style="min-width: 350px;">
                {{ picker.startDate | date_normal }} - {{ picker.endDate | date_normal }}
            </template>
        </date-range-picker>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
    components: { DateRangePicker },
    props: ['value'],
    data: function() {
        return {
        };
    },
    methods: {
        updateValue: function(value) {
            this.$emit('input', value);
        }
    }
}
</script>

<style>
.calendars {
    flex-wrap: unset !important;
}
</style>